<template>
  <section class="main-page">
    <section class="page-section mb-3">
      <div class="container">
        <div class="card">
          <div class="text-2xl text-primary font-bold">
            {{ $t("home") }}
          </div>
        </div>
      </div>
    </section>
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="col-12 md:col-6 sm:col-12 comp-grid">
            <div
              :class="
                user.application_type == postgraduate ? `sticky-item` : ``
              "
            >
              <div class="">
                <div class="card nice-shadow-18">
                  <small class="text-gray-500">
                    {{ $utils.humanDate($utils.dateTimeNow()) }}
                  </small>
                  <div class="text-2xl font-bold mt-1">
                    {{ $t("welcome_to_gctu") }}
                  </div>
                  <div class="my-3">
                    <!-- <p>
                      {{ $t("click_button_to_load_applcaition_form") }}
                    </p> -->
                  </div>
                  <div v-if="currentAppConfig" class="">
                    <Divider />
                    <div class="">
                      <div class="my-3">
                        <div v-if="user.application_ref" class="mb-2">
                          <Avatar
                            style="background-color: #292866; color: #ffffff"
                            icon="pi pi-user"
                            shape="circle"
                            size="small"
                          />
                          <span class="ml-2"
                            >{{ $t("Application Number") }}:</span
                          >
                          <span class="ml-1 text-md font-bold">
                            {{ user.application_ref }}</span
                          >
                        </div>
                        <div v-if="user.application_ref" class="mb-2">
                          <Avatar
                            style="background-color: #292866; color: #ffffff"
                            icon="pi pi-key"
                            shape="circle"
                            size="small"
                          />
                          <span class="ml-2">{{ $t("Serial Number") }}:</span>
                          <span class="ml-1 text-md font-bold">
                            {{ user.serial_number }}</span
                          >
                        </div>
                        <div v-if="user.academic_year" class="mb-2">
                          <Avatar
                            style="background-color: #292866; color: #ffffff"
                            icon="pi pi-calendar"
                            shape="circle"
                            size="small"
                          />
                          <span class="ml-2">{{ $t("academic_year") }}:</span>
                          <span class="ml-1 text-md font-bold">
                            {{ user.academic_year }},
                            {{ user.academic_month }}</span
                          >
                        </div>
                        <div class="mb-2">
                          <Avatar
                            style="background-color: #292866; color: #ffffff"
                            icon="pi pi-file"
                            shape="circle"
                            size="small"
                          />
                          <span class="ml-2">{{ $t("form_type") }}: </span>
                          <span class="ml-1 text-md font-bold">
                            {{ user.application_type }}</span
                          >
                        </div>
                        <div v-if="user.application_comments" class="mt-3">
                          <div class="card bg-pink-100">
                            <div class="flex">
                              <div class="mr-3">
                                <Avatar
                                  style="
                                    background-color: #ffffff;
                                    color: #000000;
                                  "
                                  icon="pi pi-exclamation-triangle"
                                  shape="circle"
                                  size="large"
                                />
                              </div>
                              <div class="mb-2">
                                <span class="text-md">
                                  {{ user.application_comments }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="user.application_type != postgraduate">
                      <Divider />
                      <table class="p-datatable-table w-full">
                        <tbody>
                          <tr v-for="n in 3" :key="n">
                            <td class="text-left">
                              <Skeleton height="2rem" class="mx-2 my-1" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <Divider />
                    </div>
                  </div>
                  <div v-if="user.application_type != postgraduate" class="">
                    <router-link to="/applicants/edit">
                      <Button
                        :class="
                          user.application_status != 'Completed'
                            ? ''
                            : 'p-button-success'
                        "
                        :label="
                          user.application_status != 'Completed'
                            ? $t('open_application_form')
                            : $t('application_summary')
                        "
                        class="w-full"
                      />
                    </router-link>
                  </div>
                </div>
                <div v-if="user.application_type != postgraduate" class="grid">
                  <div class="col-12 md:col-6 sm:col-6">
                    <router-link :to="`applicants/edit`">
                      <div class="card bg-indigo-100">
                        <div class="text-center">
                          <div class="mb-3" style="width: auto">
                            <Avatar
                              icon="pi pi-file"
                              size="large"
                              class="text-white bg-indigo-800"
                            />
                          </div>
                          <div class="font-bold">
                            {{ $t("my_application") }}
                          </div>
                        </div>
                        <span class="text-500 text-sm"></span>
                      </div>
                    </router-link>
                  </div>
                  <div class="col-12 md:col-6 sm:col-6">
                    <router-link :to="`applicants/results`">
                      <div class="card bg-green-100">
                        <div class="text-center">
                          <div class="mb-3" style="width: auto">
                            <Avatar
                              icon="pi pi-id-card"
                              size="large"
                              class="text-white bg-green-500"
                            />
                          </div>
                          <div class="font-bold">
                            {{ $t("application_results") }}
                          </div>
                        </div>
                        <span class="text-500 text-sm"></span>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="flex">
                  <div class="mr-3">
                    <Avatar
                      style="background-color: #292866; color: #ffffff"
                      icon="pi pi-question"
                      shape="circle"
                      size="large"
                    />
                  </div>
                  <div class="mb-2">
                    <span
                      v-if="user.application_type != postgraduate"
                      class="text-md"
                    >
                      For any queries please contact Admissions Office Main
                      Hotline/Whatsapp Number <b>+233(0)501579732</b>. Thank you
                    </span>
                    <span v-else class="text-md">
                      <div class="text-md font-bold mb-2"> ADDRESS: </div>
                      <div class="mb-2">Email: info@gtuc.edu.gh </div>
                      <div class="mb-2">Tel: +233(0) 302 221446/302 21412/302 221479</div>
                      <div class="mb-2"> Skype: gtuconline </div>
                      <div class="mb-2"> Digital Adress: GA-167-2979 </div>
                      <div class="mb-2"> PMB 100, Tesano, Accra – Ghana </div>
                      <div class="mb-2">Off J.A Kuffour Avenue, <br />Adjacent the Police Training School,Tesano, Accra </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-6 sm:col-12 comp-grid">
            <div
              v-if="user.application_type != postgraduate"
              class="card nice-shadow-18"
            >
              <div class="my-3">
                <div class="grid">
                  <div class="col-12">
                    <Timeline
                      :value="[
                        {
                          title: $t('form_created'),
                          subtitle: $t('completed'),
                          icon: 'pi pi-check',
                          color: successColor,
                          button: false,
                        },
                        {
                          title: $t('application_information'),
                          subtitle: user.country
                            ? $t('completed')
                            : $t('pending'),
                          icon: 'pi pi-user-edit',
                          color: user.country ? successColor : pendingColor,
                          button: true,
                          index: 0,
                        },
                        {
                          title: $t('bio_information'),
                          subtitle: user.firstname
                            ? $t('completed')
                            : $t('pending'),
                          icon: 'pi pi-user-edit',
                          color: user.firstname ? successColor : pendingColor,
                          button: true,
                          index: 1,
                        },
                        {
                          title: $t('family_information'),
                          subtitle: user.fathers_name
                            ? $t('completed')
                            : $t('pending'),
                          icon: 'pi pi-users',
                          color: user.firstname ? successColor : pendingColor,
                          button: true,
                          index: 2,
                        },
                        {
                          title: $t('academic_history'),
                          subtitle: user.attended_shs
                            ? $t('completed')
                            : $t('pending'),
                          icon: 'pi pi-book',
                          color: user.attended_shs
                            ? successColor
                            : pendingColor,
                          button: true,
                          index: 3,
                        },
                        {
                          title: $t('programme_selections'),
                          subtitle: user.first_programme_id
                            ? $t('completed')
                            : $t('pending'),
                          icon: 'pi pi-th-large',
                          color: user.first_programme_id
                            ? successColor
                            : pendingColor,
                          button: true,
                          index: 4,
                        },
                        {
                          title: $t('funding_or_scholarship'),
                          subtitle: user.consider_scholarship
                            ? $t('completed')
                            : $t('pending'),
                          icon: 'pi pi-star',
                          color: user.consider_scholarship
                            ? successColor
                            : pendingColor,
                          button: true,
                          index: 5,
                        },
                        {
                          title: $t('payment'),
                          subtitle:
                            user.payment_status == paid
                              ? $t('completed')
                              : $t('pending'),
                          icon: 'pi pi-wallet',
                          color:
                            user.payment_status == paid
                              ? successColor
                              : pendingColor,
                          button: true,
                          index: 6,
                        },
                        {
                          title: $t('application_submitted'),
                          subtitle:
                            user.application_status == completed
                              ? $t('completed')
                              : $t('pending'),
                          icon: 'pi pi-send',
                          color:
                            user.application_status == completed
                              ? successColor
                              : pendingColor,
                          button: true,
                          index: 7,
                        },
                      ]"
                      align="alternate"
                      class="customized-timeline"
                    >
                      <template #marker="slotProps">
                        <span
                          style="width: 2.4rem; height: 2.4rem"
                          class="custom-marker shadow-2"
                          :style="{ backgroundColor: slotProps.item.color }"
                        >
                          <i :class="slotProps.item.icon"></i>
                        </span>
                      </template>
                      <template #content="slotProps">
                        <div class="">
                          <div class="grid">
                            <div class="col-12">
                              <div class="text-md font-bold">
                                {{ slotProps.item.title }}
                              </div>
                              <div
                                class="text-xs"
                                :style="{ color: slotProps.item.color }"
                              >
                                {{ slotProps.item.subtitle }}
                              </div>
                              <div
                                v-if="slotProps.item.button == true"
                                class="mt-2"
                              >
                                <Button
                                  :label="$t('view')"
                                  class="p-button-link p-button-sm p-0"
                                  :class="
                                    slotProps.item.color == pendingColor
                                      ? 'p-button-warning'
                                      : 'p-button-success'
                                  "
                                  @click="editApplication(slotProps.item.index)"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </Timeline>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="card nice-shadow-18">
              <download-graduate-form-page
                is-sub-page
                ref="downloadGraduateFormPage"
                id=""
                :show-header="true"
                v-if="ready"
              >
              </download-graduate-form-page>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>
<script>
import downloadGraduateFormPage from "../applicants/downloadgraduateform.vue";
import { PageMixin } from "../../mixins/page.js";
import Timeline from "primevue/timeline";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "homePage",
  components: { Timeline, downloadGraduateFormPage },
  mixins: [PageMixin],
  props: {
    pageName: {
      type: String,
      default: "home",
    },
    routeName: {
      type: String,
      default: "homePage",
    },
  },
  meta() {
    return {
      title: this.pageTitle,
    };
  },
  data: function () {
    return {
      ready: false,
      successColor: "#087408",
      pendingColor: "#9f6810",
      paid: "Paid",
      completed: "Completed",
      postgraduate: "Postgraduate",
    };
  },
  computed: {
    ...mapGetters("app_config", ["currentAppConfig"]),
    ...mapGetters("auth", ["user"]),
    pageTitle: {
      get: function () {
        return this.$t("welcome_to_caps");
      },
    },
  },
  methods: {
    editApplication(index) {
      let pageIndex = index;
      this.$router.push({
        name: "applicantsedit",
        params: { pageIndex },
        props: { pageIndex },
      });
    },
  },
  watch: {},
  async mounted() {
    this.ready = true;
    await this.$store.dispatch("auth/getUserData");
    await this.$store.dispatch("app_config/fetchAppConfig");
  },
  async created() {
    // await this.$store.dispatch("auth/getUserData");
  },
};
</script>
<style lang="scss" scoped>
.custom-marker {
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 50%;
  z-index: 1;
}

::v-deep(.p-timeline-event-content) ::v-deep(.p-timeline-event-opposite) {
  line-height: 1;
}
</style>
